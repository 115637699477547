<template>
  <div class="root">
    <ul v-if="!editMode" class="reference-list" :title="orderedReferences?.join(' | ')">
      <li v-if="showNavigation" class="reference-list-item">
        <a :href="`/inventions/${invention.id}`" target="_blank" :title="title" class="icon" @click.stop>
          <Icon name="open-in-new" size="sm" />
        </a>
      </li>
      <li v-if="matterDirectory" class="reference-list-item">
        <a :href="`ph://${matterDirectory}`" title="Open directory" class="icon" @click.stop="open">
          <Icon v-if="opening" name="loading" size="sm" spin />
          <Icon v-else name="folder" size="sm" />
        </a>
      </li>
      <li v-for="reference of orderedReferences" :key="reference" class="reference-list-item reference">
        <div :title="reference">
          <a v-if="canNavigateExternaly(reference) && externalNavigationEnabled" :href="getUrl(reference)" target="_blank">{{
            formatReference(reference)
          }}</a>
          <span v-else>{{ formatReference(reference) }}</span>
        </div>
      </li>
    </ul>
    <Multiselect
      v-else
      :value="orderedReferences"
      :options="orderedReferences"
      placeholder=""
      :taggable="true"
      :can-clear="false"
      :show-options="false"
      @change="$emit('references:updated', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/common/Icon';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    Icon,
    Multiselect
  },
  props: {
    invention: {
      type: Object,
      required: true
    },
    externalNavigationEnabled: {
      type: Boolean,
      default: false
    },
    showNavigation: {
      type: Boolean,
      default: true
    },
    showPrefixes: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['references:updated'],
  data() {
    return {
      opening: false,
      references: this.invention.references,
      title: this.invention.title,
      timestamp: +new Date(),
      order: ['HARRITY', 'FIP', 'US']
    };
  },
  computed: {
    ...mapGetters({
      root: 'directoryIndex/root'
    }),
    matterDirectory() {
      return this.root(this.invention.references?.find(r => r.startsWith('HARRITY')));
    },
    orderedReferences() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.references
        .filter(r => r)
        .sort((a, b) => {
          const aPrefix = a.split(':')[0];
          const bPrefix = b.split(':')[0];

          const aIndex = this.order.indexOf(aPrefix);
          const bIndex = this.order.indexOf(bPrefix);

          if (aIndex === bIndex) {
            return 0;
          }

          if (aIndex < 0) {
            return 1;
          }

          if (bIndex < 0) {
            return -1;
          }

          return aIndex - bIndex;
        });
    }
  },
  methods: {
    async open() {
      this.opening = true;
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.opening = false;
    },
    formatReference(reference) {
      if (this.showPrefixes) {
        return reference;
      }

      const [left, ...right] = reference.split(':');

      if (right.length === 0) {
        return left;
      }
      return right.join(':');
    },
    getUrl(reference) {
      if (!reference) {
        return '';
      }

      const foundationIpUrl = this.$settings.fip.url;
      const [left, ...right] = reference.split(':');
      const type = this.referenceType(reference);

      switch (type) {
        case 'fip':
          return `${foundationIpUrl}/navigation!navTo.action?viewPage=matterhome.do&page=activity&matterId=${right}`;
        case 'harrity':
          return `${foundationIpUrl}/navigation!showMain.action?showSearch=true&topFrameSearchCriteria=fileNo&topFrameSearchValue=${right}`;
        case 'patentcenter':
          return `https://patentcenter.uspto.gov/#!/applications/${right[0]?.replaceAll('/', '').replaceAll(',', '')}`;
        case 'uspto':
          return `https://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PALL&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.htm&r=1&f=G&l=50&s1=${right[0]}.PN.&OS=PN/${right[0]}&RS=PN/${right[0]}`;
        default:
          return '';
      }
    },
    canNavigateExternaly(reference) {
      const type = this.referenceType(reference);

      return ['fip', 'patentcenter', 'harrity', 'uspto'].includes(type);
    },
    referenceType(reference) {
      if (!reference) {
        return '';
      }

      if (reference.startsWith('FIP:')) {
        return 'fip';
      }

      if (reference.startsWith('HARRITY:')) {
        return 'harrity';
      }

      if (reference.startsWith('US:') && /\d{1,}\/\d{3}\,\d{3}/.test(reference)) {
        return 'patentcenter';
      }

      if (reference.startsWith('US:') && /\d{1,}\,\d{3}\,\d{3}/.test(reference)) {
        return 'uspto';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.reference-list {
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    margin-right: 0.5rem;
  }

  .reference-list-item {
    font-weight: 500;
    display: inline-block;

    a {
      font-size: 0.75rem;
      font-weight: 700;
    }

    &:first-child {
      color: var(--theme-primary);
      padding-left: 0;
      font-weight: 700;
    }

    span {
      font-size: 0.75rem;
      line-height: 1;
      color: var(--theme-on-background-accent);
      white-space: nowrap;
    }
    &:not(:last-child) {
      div {
        &:after {
          content: '|';
          color: var(--theme-on-surface);
          margin: 0rem 0.4rem;
          font-weight: 700;
        }
      }
    }
  }

  .icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    transform: translateY(1px);
  }
}
</style>
